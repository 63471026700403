<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>
  
  <script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Bar },
  //   data() {
  //     return {
  // chartData: {
  // labels: ["Altruistic", "Power", "Existential", "Emotional"],
  // datasets: [
  //   {
  //     // label: "hi",
  //     data: [45, 38, 90, 14],
  //     backgroundColor: "#B19F7A",
  //     barPercentage: 0.8,
  //   },
  // ],
  // },
  //   chartOptions: {
  //     responsive: true,
  //     plugins: {
  //       legend: {
  //         display: false,
  //       },
  // },
  // scales: {
  //   y: {
  //     type: "linear",
  //     min: 0,
  //     max: 100,
  //   },
  // },
  // elements: {
  //   bar: {
  //     borderRadius: 4,
  // inflateAmount: 15,
  // barPercentage: 1,
  //           },
  //         },
  //       },
  //     };
  //   },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    quizData: {
      type: Object,
    },
    chartData: {
      type: Object,
    },
  },
  data() {
    return {
      // chartData: {},
      // chartData: {
      //   labels: Object.keys(this.quizData),
      //   datasets: [
      //     {
      //       // label: "hi",
      //       data: Object.values(this.quizData),
      //       backgroundColor: "#B19F7A",
      //       barPercentage: 0.8,
      //     },
      //   ],
      // },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            type: "linear",
            min: 0,
            max: 100,
          },
        },
        elements: {
          bar: {
            borderRadius: 4,
            // inflateAmount: 15,
            // barPercentage: 1,
          },
        },
      },
    };
  },
  mounted() {
    // if quizData is not empty, then update chartData
    // if (Object.keys(this.quizData).length > 0) {
    //   this.chartData = {
    //     labels: Object.keys(this.quizData),
    //     datasets: [
    //       {
    //         // label: "hi",
    //         data: Object.values(this.quizData),
    //         backgroundColor: "#B19F7A",
    //         barPercentage: 0.8,
    //       },
    //     ],
    //   };
    // }
  },
};
</script>